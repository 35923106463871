import moment from 'moment'
import { attendanceColorCodes } from '@src/constants/attandance/attandance-constant.js'
import datetime from '@src/constants/date-time-constants.js'
export const findCollappsingForRecords = (current, all) => {
  return all
    .filter((record) => {
      if (record.id === current.id) {
        return false
      } else {
        return timeCollapsed(current, record)
      }
    })
    .map((r) => r.id)
}

/**
 * Time Collapsed
 * @param { Object , Object }  - current , record
 * @returns {Boolean}
 * @description Function Description : Comparing time for overlapping records in weekly range
 * - checking if the records and current selected day is same
 * - calling the timeComparison method and checking the overlapping schedule in a day of weekly range
 */
export const timeCollapsed = (current, record) => {
  if (current.day !== record.day) {
    return false
  } else if (
    timeComparison(current.startTime, record.startTime) === 1 &&
    timeComparison(current.endTime, record.startTime) === 2 &&
    timeComparison(current.endTime, record.endTime) === 1
  ) {
    return true
  } else if (
    timeComparison(current.startTime, record.startTime) === 2 &&
    timeComparison(current.endTime, record.endTime) === 1
  ) {
    return true
  } else if (
    timeComparison(current.startTime, record.startTime) === 1 &&
    timeComparison(current.endTime, record.endTime) === 2
  ) {
    return true
  } else if (
    timeComparison(current.startTime, record.startTime) === 3 ||
    timeComparison(current.endTime, record.endTime) === 3
  ) {
    return true
  } else {
    return false
  }
}

/**
 * Time Comparison
 * @param { Object , Object }  - timeOne , timeTwo
 * @returns {Integer}
 * @description Function Description : Comparing time for overlapping records in weekly range
 * - getting the currenttime as first argument and record's time as the second argument
 * - comparing (current start or end time) with (record's start or end time) in schedules for the specific day
 * - comparing the two arguments with each other and returning them according to their precidence
 */
const timeComparison = (timeOne, timeTwo) => {
  const timeOneSplit = moment(timeOne).utcOffset(0).format(datetime.time12H).split(':')
  const timeTwoSplit = moment(timeTwo).utcOffset(0).format(datetime.time12H).split(':')
  if (timeOneSplit[0] < timeTwoSplit[0]) {
    return 1
  } else if (timeOneSplit[0] > timeTwoSplit[0]) {
    return 2
  } else {
    if (timeOneSplit[1] < timeTwoSplit[1]) {
      return 1
    } else if (timeOneSplit[1] > timeTwoSplit[1]) {
      return 2
    } else {
      if (timeOneSplit[2] < timeTwoSplit[2]) {
        return 1
      } else if (timeOneSplit[2] > timeTwoSplit[2]) {
        return 2
      } else {
        return 3
      }
    }
  }
}

export const attendanceColor = (status) => {
  if (status === 'present') {
    return attendanceColorCodes.PRESENT
  }
  if (status === 'absent') {
    return attendanceColorCodes.ABSENT
  } else {
    return attendanceColorCodes.DELAY
  }
}
