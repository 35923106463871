<template>
  <section class="schedule-responsive overflow-auto overscroll-contain max--height pb-0.5">
    <div v-if="!$slots.noEvent" class="schedular">
      <div class="schedular__head w-full sticky top-0 bg-primary-white z-10">
        <ul
          class="flex items-center md:ltr:pl-1 md:rtl:pr-1 bg-primary-white border md:rounded-t-lg border-primary-grey"
        >
          <li
            refs="timeHeading"
            class="sticky ltr:left-0 rtl:right-0 bg-primary-white py-4 font-roboto font-medium text-text-color text-sm capitalize"
            :style="{ width: `${90}px` }"
          >
            <p class="py-1 px-3 ltr:border-r border-primary-grey">
              {{ $t('attendanceTranslation.Time') }}
            </p>
          </li>
          <li
            v-for="(head, index) in tableHead"
            :key="head"
            class="py-4 font-roboto font-medium text-text-color text-sm capitalize box-border"
            :style="{ width: theads.length > 1 ? `${113.9}px` : `${100}%` }"
          >
            <p
              :class="{ 'border-r border-primary-grey': index !== tableHead.length - 1 }"
              class="py-1 px-3 rtl:border-r border-primary-grey"
            >
              {{ $t(`attendanceTranslation.${head}`) }}
            </p>
          </li>
        </ul>
      </div>
      <div
        class="schedular__body relative ltr:border-l rtl:border-r border-b rounded-b-lg md:ltr:pl-1 md:rtl:pr-1 border-primary-grey"
      >
        <ul v-for="time in tableTimeRange" :key="time" class="flex items-center sticky--leftbar">
          <li
            :style="{ height: `${114}px`, width: `${90}px` }"
            class="flex justify-center font-roboto ltr:border-r rtl:border-l border-b border-primary-grey font-normal text-text-color text-sm uppercase"
          >
            <p class="pt-3">{{ time }}</p>
          </li>
          <li
            v-for="head in tableHead"
            :key="head"
            :style="{ height: `${114}px`, width: `${114}px` }"
            class="flex items-center flex-1 border-b ltr:border-r rtl:border-l border-primary-grey"
          ></li>
        </ul>
        <Event
          v-for="(eventObj, idx) in events"
          :key="eventObj.id + idx"
          :table-time="tableTime"
          :table-head="tableHead"
          :event="eventObj"
          :hide-overlap="eventObj.isOverlapped === undefined ? hideOverlap : !eventObj.isOverlapped"
          :hide-actions="hideActions"
          @eventClicked="$emit('viewEvent', eventObj.id)"
        >
          <template v-slot="{ event }">
            <p class="truncate text-xs font-roboto font-medium">{{ event.title }}</p>
            <p class="truncate text-xs font-roboto font-normal">
              <span v-i18n="dashboard">Section</span>
              : {{ (event && event.section.title) || '' }}
            </p>
            <p class="truncate text-xs font-roboto font-normal">
              <span v-i18n="dashboard">Teacher</span>
              : {{ (event && event.teacher) || '' }}
            </p>
            <p
              v-if="eventObj.isOverlapped === true"
              class="truncate text-xs font-roboto font-medium cursor-pointer"
            >
              <span v-i18n="dashboard">View More</span>
            </p>
          </template>
          <template v-slot:actions>
            <ul v-if="!hideActions" class="py-1 actions-list">
              <li
                class="actions-list__item w-24 cursor-pointer hover:bg-bg-color-light text-text-color text-xs px-2 py-1"
                @click="editEvent($event, event)"
              >
                Edit
              </li>
              <li
                class="actions-list__item w-24 cursor-pointer hover:bg-bg-color-light text-text-color text-xs px-2 py-1"
                @click="deleteEvent($event, event)"
              >
                Delete
              </li>
            </ul>
          </template>
        </Event>
      </div>
    </div>
    <slot name="noEvent"></slot>
  </section>
</template>

<script>
import { tableSide } from '@src/utils/timeTableUtils.js'
import Event from '../../attendance/Event.vue'

export default {
  name: 'Schedule',
  components: {
    Event,
  },
  props: {
    theads: {
      type: Array,
      default: () => [],
    },
    tableTime: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    hideOverlap: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['viewEvent', 'editEvent', 'deleteEvent'],
  data() {
    return {
      tHeadLable: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      dashboard: 'dashboard',
      tableTimeRange: [],
    }
  },
  computed: {
    tableHead() {
      const startIdx = this.tHeadLable.indexOf(this.theads[0])
      const endIdx = this.tHeadLable.indexOf(this.theads[1])
      let tableHeadTemp = []
      if (this.theads.length > 1) {
        if (startIdx > endIdx) {
          tableHeadTemp = this.tHeadLable.slice(startIdx)
          tableHeadTemp = tableHeadTemp.concat(this.tHeadLable.slice(0, endIdx + 1))
        } else {
          tableHeadTemp = this.tHeadLable.slice(startIdx, endIdx + 1)
        }
      } else {
        tableHeadTemp = this.theads
      }
      return tableHeadTemp
    },
  },
  watch: {
    tableTime: {
      immediate: true,
      handler() {
        this.setTableSide()
      },
    },
  },
  methods: {
    editEvent(e, event) {
      e.stopPropagation()
      this.$emit('editEvent', event)
    },
    deleteEvent(e, event) {
      e.stopPropagation()
      this.$emit('deleteEvent', event)
    },
    setTableSide() {
      this.tableTimeRange = tableSide(this.tableTime)
    },
  },
}
</script>

<style lang="scss" scoped>
.schedular__body ul:last-of-type li:first-child {
  border-bottom: none;
}
.schedular__body ul:last-of-type li:last-child {
  border-bottom: none;
  border-bottom-right-radius: 8px;
}

.schedular__body ul:last-of-type li:not(:first-child):not(:last-child) {
  border-bottom: none;
}
body[dir='rtl'] {
  .schedular__body ul:last-of-type li:last-child {
    border-bottom-left-radius: 8px;
  }
}
</style>
