<template>
  <div>
    <div v-if="isLoading"><Loader class="mt-20" :content="true" /></div>
    <TableWrapper v-else-if="!isLoading && attendanceList.length">
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(attendance, idx) in attendanceList" :key="idx">
          <TD v-show="showColumnInDataTable(filtersData, 'Student Name', ['Student Name'])">
            <span @click="redirectToStudentprofile(attendance.student)">
              <SingleUserDisplay :user="attendance.student" label="full_name" :image="true" />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status', ['Status'])">
            <UiStatus :title="attendance.status" :success="attendance.status === PRESENT" />
          </TD>

          <TD
            v-show="showColumnInDataTable(filtersData, 'Period', ['Period'])"
            @click="redirectToDetailPage(attendance.period)"
          >
            <span class="text-primary-purple-600 cursor-pointer">
              {{ getScheduleLabel(attendance.period) }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Comment')">
            {{ attendance.comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <div v-else>
      <NoRecordFound />
    </div>
    <div class="-mr-5 md:mr-0">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script>
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { formatDate } from '@utils/moment.util'
import DATETIME_CONSTANT from '@src/constants/date-time-constants.js'
import generalMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { mapState } from 'vuex'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import fileMixin from '@src/mixins/file-mixins'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    SingleUserDisplay,
    Loader,
    NoRecordFound,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixins, fileMixin, scrollMixin],
  props: {
    attendanceList: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    filtersData: {
      type: Object,
      default: () => {},
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      PRESENT: 'present',
      attendanceTranslation: 'attendanceTranslation',
      skipForFilterColumns: ['Student Name', 'Status', 'Period'],
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
    }),
  },
  methods: {
    showColumnInDataTable,
    redirectToStudentprofile(student) {
      if (this.activeRole !== TENANT_ROLES.SECTION_STUDENT) {
        redirectToStudentMode(student, 'students-period-attendance')
      }
    },
    redirectToDetailPage(period) {
      this.$router?.push({ name: 'students-period-attendance-details', params: { id: period.id } })
    },
    getScheduleLabel(schedule) {
      return `${schedule.subject.title} : ( ${formatDate(
        schedule.started_at,
        DATETIME_CONSTANT.time12H,
      )} - ${formatDate(schedule.ended_at, DATETIME_CONSTANT.time12H)} )`
    },
  },
}
</script>

<style scoped lang="scss">
.attendance-list-view {
  &__header,
  &__body {
    > div {
      div {
        flex: 1;
        width: fit-content;
        min-width: 80px;
        padding: 10px;
        white-space: nowrap;
      }
      div:first-of-type {
        flex: 0;
      }
    }
  }
}
.student-border-top {
  border-top-width: 1px;
}
</style>
