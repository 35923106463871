import moment from 'moment'
import datetime from '@src/constants/date-time-constants.js'
import { convertUTCTimeIntoLocalTime } from '@src/utils/generalUtil.js'
import {
  attendanceColor,
  findCollappsingForRecords,
} from '@src/router/views/attendance/attendanceUtils.js'
export const getStudentAttendanceMonthly = (resp) => {
  const attendances = []

  Object.keys(resp.records).forEach((date) => {
    resp.records[date].forEach((att, idx) => {
      const attendance = {
        id: att.subject_attendance[0].id,
        presentRate: att.meta.present_rate,
        date: date,
        subject: {
          name: att.subject_attendance[0].schedule.subject.title,
          color: att.subject_attendance[0].schedule.subject.color,
          presentColor: attendanceColor(att.subject_attendance[0].status),
        },
        lesson: {
          title: att.subject_attendance[0].schedule?.lesson?.title || '',
          type: att.subject_attendance[0].schedule?.lesson?.type || '',
        },
        section: {
          title: att.subject_attendance[0]?.schedule?.section?.title,
          id: att.subject_attendance[0]?.schedule?.section?.id,
        },

        teacher:
          att?.subject_attendance[0]?.schedule?.teacher?.first_name ||
          '' + ' ' + att?.subject_attendance[0]?.schedule?.teacher?.last_name ||
          '',
        startTime: moment(att.subject_attendance[0].schedule.started_at).format(datetime.time12H),
        endTime: moment(att.subject_attendance[0].schedule.ended_at).format(datetime.time12H),
        present: att.meta.presents,
        absent: att.meta.absents,
        delay: att.meta.delays,
        room: att.subject_attendance[0].schedule?.room?.title,
        topic: att.subject_attendance[0].schedule.topic,
      }
      if (idx < 3) {
        attendance.isAction = false
        attendance.room = att.subject_attendance[0].schedule?.room?.title
        attendances.push(attendance)
      } else if (idx === 3) {
        attendance.isAction = true
        attendance.startTime = moment(att.subject_attendance[0].schedule.start_time).format(
          datetime.time12H,
        )
        attendance.endTime = moment(att.subject_attendance[0].schedule.end_time).format(
          datetime.time12H,
        )
        attendance.room = att.subject_attendance[0]?.schedule?.room?.number

        attendances.push(attendance)
      } else if (idx > 3) {
        attendance.isAction = null
        attendance.room = att.subject_attendance[0].schedule.room.number

        attendances.push(attendance)
      }
    })
  })
  return attendances
}

export const getStudentAttendanceWeek = (records) => {
  const attendances = []

  Object.keys(records.records).forEach((date) => {
    records.records[date].forEach((record) => {
      record.subject_attendance.forEach((idx) => {
        const color = attendanceColor(idx.status)
        const count = {
          present: record.meta.presents,
          absent: record.meta.absents,
          delay: record.meta.delays,
        }
        const event = getSubjectEvent(idx.schedule, color, count)

        attendances.push(event)
      })
    })
  })

  records = attendances

  const allEvents = records.reduce((acc, record) => {
    const event = {
      ...record,
      parentId: null,
      overlappedIds: [],
    }

    event.overlappedIds = findCollappsingForRecords(record, records)

    const isExist = []

    event.overlappedIds.forEach((id) => {
      if (acc[id]) {
        isExist.push(id)
      }
    })

    if (isExist.length === 0) {
      acc[event.id] = { ...event }
    } else {
      if (isExist.length !== 1) {
        const allOverlapped = isExist.reduce((ac, val) => {
          acc[val].parentId = event.id
          ac = [...ac, ...acc[val].overlappedIds]

          return ac
        }, [])

        event.overlappedIds = [...event.overlappedIds, ...allOverlapped]
      } else {
        if (!acc[isExist[0]].parentId) {
          acc[isExist[0]].overlappedIds = [...acc[isExist[0]].overlappedIds, ...event.overlappedIds]

          event.parentId = isExist[0]
        } else {
          acc[acc[isExist[0]].parentId].overlappedIds = [
            ...acc[acc[isExist[0]].parentId].overlappedIds,
            ...event.overlappedIds,
          ]

          event.parentId = acc[isExist[0]].parentId
        }
      }

      acc[event.id] = { ...event }
    }

    return acc
  }, {})

  return Object.values(allEvents)
    .filter((e) => !e.parentId)
    .map((e) => {
      return {
        ...e,
        overlappedIds: e.overlappedIds
          .filter((id) => id !== e.id)
          .reduce((ac, val) => {
            if (!ac.includes(val)) {
              ac.push(val)
            }
            return ac
          }, []),
      }
    })
    .map((record) => {
      return getSubjectEventUtc(record)
    })
}

export const getSubjectEvent = (subject, color, count) => {
  let teacherName = null
  if (subject.teacher) {
    if (subject.teacher.first_name !== undefined && subject.teacher.last_name !== undefined) {
      teacherName = subject.teacher.first_name + ' ' + subject.teacher.last_name
    } else if (subject.teacher.first_name !== undefined) {
      teacherName = subject.teacher.first_name
    }
  } else {
    teacherName = ' '
  }
  const event = {
    id: subject.id,
    isOverlapped: false,
    title: subject.subject.title,
    section: subject.section.title,
    teacher: teacherName,
    subject: {
      title: subject.subject.title,
      sectionClassSubjectId: subject.section_id,
      subjectId: subject.subject.id,
    },
    details: true,
    day: moment(subject.started_at).format(datetime.day).toLowerCase(),
    date: subject.started_at.split('T')[0],
    room: subject?.room?.title,
    room_id: subject?.room?.id,
    startTime: convertUTCTimeIntoLocalTime(subject.started_at.split('T')[1]),
    endTime: convertUTCTimeIntoLocalTime(subject.ended_at.split('T')[1]),
    color,
    present: count.present,
    absent: count.absent,
    delay: count.delay,
  }
  return event
}
/*
 * Use as reducer function to reduce event data
 * Get payload as param use to reduce the subject data
 */
export const getSubjectEventUtc = (payload) => {
  const event = {
    id: payload.id,
    isOverlapped: payload.overlappedIds?.length > 0,
    overlappedIds: payload.overlappedIds,
    title: payload.title,
    subject: {
      title: payload.subject.title,
      sectionClassSubjectId: payload.subject.sectionClassSubjectId,
      subjectId: payload.subject.subjectId,
    },
    section: { title: payload.title, id: payload.id },
    teacher: payload?.teacher || '',
    teacher_id: payload?.id,
    details: true,
    day: payload.day.toLowerCase(),
    date:
      new Date(payload.date).getFullYear() +
      '-' +
      (new Date(payload.date).getMonth() + 1) +
      '-' +
      new Date(payload.date).getDate(),
    room: payload.room,
    room_id: payload.room_id,
    startTime: payload.startTime,
    endTime: payload.endTime,
    color: payload.color,
    present: payload.present,
    absent: payload.absent,
    delay: payload.delay,
  }

  return event
}
