<template>
  <UiModalContainer
    footer-classes="flex justify-end"
    :modal-show="modal"
    :modal-width="40"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-i18n="attendanceTranslation">SAM_HT</span>
    </template>

    <template>
      <div
        v-for="(attendance, idx) in attendances"
        :key="idx"
        class="px-3 md:px-8"
        :class="{ 'mb-8': idx === attendances.length - 1 }"
      >
        <div class="flex gap-4 flex-row items-center mt-8">
          <span class="text-2xl text-text-color font-medium font-roboto">
            {{ attendance.subject.name || attendance.subject.title }}
          </span>
          <div
            class="h-4 md:h-5 w-4 md:w-5 rounded-full"
            :style="{ backgroundColor: attendance.subject.color }"
          ></div>
        </div>
        <div class="flex flex-row mt-5 gap-2 md:gap-7">
          <div class="flex flex-1 gap-5 flex-col">
            <div class="flex items-center">
              <Icon icon="calender" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ attendance.date }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_ST</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.section.title }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_PT</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.present }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_DT</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.delay }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_LT</span>
              :
              <span v-if="attendance.lesson" class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.lesson.title }}
              </span>
            </div>
          </div>
          <div class="flex gap-5 flex-1 flex-col text-left">
            <div class="flex items-center clock-icon whitespace-nowrap">
              <Icon icon="clock" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light uppercase">
                {{ formatTime(attendance.startTime) }} - {{ formatTime(attendance.endTime) }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_TT</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal whitespace-nowrap">
                {{ attendance.teacher || '' }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_RT</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.room }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_AT</span>
              :
              <span class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.absent }}
              </span>
            </div>
            <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
              <span v-i18n="attendanceTranslation">SAM_LTT</span>
              :
              <span v-if="attendance.lesson" class="md:px-3 text-primary-grey-light font-normal">
                {{ attendance.lesson.type }}
              </span>
            </div>
          </div>
        </div>
        <hr v-if="attendances.length > 1 && idx < attendances.length - 1" class="my-5" />
      </div>
    </template>
  </UiModalContainer>
</template>

<script>
import Icon from '@components/icons/icon.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import moment from 'moment'
export default {
  components: {
    Icon,
    UiModalContainer,
  },
  props: {
    modal: { type: Boolean, default: false },
    attendances: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['toggle'],
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation',
    }
  },
  methods: {
    formatTime(time) {
      return moment(time, 'hh:mm A').format('hh:mm A')
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'confirm':
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
